@import "styles/variables.scss";
.authBody {
  width: 100%;
  height: 100%;
  background: #131517;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  padding: 30px;
  box-sizing: border-box;
  gap: 0px;
  padding-right: 0px;
  .authContent {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    box-sizing: border-box;
    .slideSection {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      width: 30dvw;
      background: $primary-color;
      box-sizing: border-box;
      padding: 30px;
      border-radius: 20px;
      .logoSection {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: auto;
        svg {
          height: 26px;
          width: auto;
          fill: $white-color;
        }
      }
      .slideText {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        box-sizing: border-box;
        gap: 30px;
        .title {
          font-size: 3.8rem;
          font-weight: 500;
        }
        .subTitle {
          font-size: 1.5rem;
          font-weight: 300;
          color: $white-color;
        }
      }
      .customerQuotes {
        .quote {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: auto;
          box-sizing: border-box;
          background: rgba(0, 0, 0, 0.25);
          border-radius: 20px;
          padding: 20px;
          gap: 20px;
          .quoteText {
            font-size: 18px;
            font-weight: 400;
            color: $white-color;
            width: 100%;
            height: auto;
          }
          .quoteAuthor {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 12px;
            .quoteAuthorImage {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              width: 54px;
              min-width: 54px;
              max-width: 54px;
              height: 54px;
              min-height: 54px;
              max-height: 54px;
              border-radius: 12px;
              img {
                width: 100%;
                height: 100%;
                border-radius: 12px;
                object-fit: cover;
              }
            }
            .quoteAuthorDetail {
              display: flex;
              flex-direction: column;
              width: 100%;
              height: auto;
              box-sizing: border-box;
              gap: 4px;
              .quoteAuthorName {
                font-size: 16px;
                font-weight: 500;
                color: $white-color;
              }
              .quoteAuthorDesignation {
                font-size: 14px;
                font-weight: 300;
                color: $white-color;
              }
            }
          }
        }
        .quoteDots {
          display: flex;
          flex-direction: row;
          justify-content: center;
          gap: 10px;
          margin-top: 20px;
          .quoteDot {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: rgba(255, 255, 255, 0.25);
            transition: 0.3s;
            &.active {
              background: $white-color;
            }
          }
        }
      }
    }
    .formSection {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      overflow: auto;
      width: calc(100% - 30dvw);
      box-sizing: border-box;
      padding-left: 40px;
      padding-right: 40px;
      gap: 40px;
      .formTitle {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        box-sizing: border-box;
        gap: 10px;
        .mobileLogo {
          display: none;
          flex-direction: row;
          width: 100%;
          height: auto;
          margin-bottom: 20px;
          svg {
            height: 26px;
            width: auto;
            fill: $primary-color;
            path {
              fill: $primary-color;
            }
          }
        }
        .title {
          font-size: 2.1rem;
          font-weight: 600;
          color: $white-color;
        }
        .subTitle {
          font-size: 18px;
          font-weight: 300;
          color: #b5bbc1;
        }
      }
      .form {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        box-sizing: border-box;
        gap: 20px;
        .formItem {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: auto;
          box-sizing: border-box;
          gap: 10px;
          .title {
            font-size: 16px;
            font-weight: 500;
            color: #dadada;
          }
          .inputWrapper {
            position: relative;
            .showHideBtn {
              position: absolute;
              right: 16px;
              z-index: 1;
              top: 50%;
              transform: translateY(-50%);
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              border: none;
              cursor: pointer;
              height: 24px;
              min-height: 24px;
              max-height: 24px;
              width: 24px;
              min-width: 24px;
              max-width: 24px;
              background: transparent;
              svg {
                width: 100%;
                height: auto;
                fill: #b5bbc1;
                transition: 0.2s;
                path {
                  transition: 0.2s;
                }
              }
              &.show {
                svg {
                  fill: $primary-color;
                }
              }
            }
          }
          input {
            background: #2e2e2e;
            border: none;
            border-radius: 6px;
            box-sizing: border-box;
            color: #fafafa;
            font-size: 18px;
            height: auto;
            outline: none;
            padding: 16px;
            transition: 0.3s;
            width: 100%;
          }
          .forgotPasswordBtn {
            background: transparent;
            width: fit-content;
            height: auto;
            padding: 0;
            box-sizing: border-box;
            color: $primary-color;
            border: none;
            margin-left: auto;
            font-size: 16px;
            font-weight: 500;
            transition: 0.3s;
            cursor: pointer;
            &:hover {
              text-decoration: underline;
              opacity: 0.8;
            }
          }
          .signBtn {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: auto;
            min-height: 50px;
            box-sizing: border-box;
            padding: 12px 12px;
            min-width: 160px;
            font-weight: 500;
            background: $primary-color;
            color: $white-color;
            margin-left: auto;
            border-radius: 12px;
            font-size: 16px;
            transition: 0.3s;
            cursor: pointer;
            border: none;
            outline: none;
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
      .switchQuestion {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        justify-content: center;
        align-items: center;
        gap: 4px;
        .switchText {
          font-size: 18px;
          font-weight: 300;
          color: #b5bbc1;
        }
        .switchBtn {
          font-size: 20px;
          font-weight: 400;
          text-decoration: underline;
          color: $primary-color;
          cursor: pointer;
          transition: 0.3s;
          &:hover {
            text-decoration: none;
            opacity: 0.8;
          }
        }
      }
      .downloadSection {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        box-sizing: border-box;
        gap: 20px;
        margin-top: 60px;
        .titleSection {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: auto;
          box-sizing: border-box;
          gap: 6px;
          .title {
            font-size: 18px;
            font-weight: 300;
            color: #b5bbc1;
          }
          .subTitle {
            font-size: 16px;
            font-weight: 500;
            color: $white-color;
          }
        }
        .storeList {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;
          height: auto;
          gap: 20px;
          flex-wrap: wrap;
          .columnItem {
            display: flex;
            flex-direction: column;
            width: auto;
            height: auto;
            box-sizing: border-box;
            gap: 20px;
          }
          .storeItem {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: auto;
            height: auto;
            border: 2px solid #2e2e2e;
            box-sizing: border-box;
            padding: 10px;
            border-radius: 12px;
            gap: 10px;
            cursor: pointer;
            transition: 0.3s;
            .storeIcon {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              width: 30px;
              min-width: 30px;
              max-width: 30px;
              height: 30px;
              min-height: 30px;
              max-height: 30px;
              svg {
                width: 100%;
                height: auto;
                fill: $white-color;
                transition: 0.2s;
              }
            }
            .storeDetail {
              display: flex;
              flex-direction: column;
              width: auto;
              height: auto;
              box-sizing: border-box;
              .title {
                font-size: 12px;
                font-weight: 300;
                color: #b5bbc1;
                white-space: nowrap;
              }
              .storeName {
                font-size: 16px;
                font-weight: 500;
                color: $white-color;
                white-space: nowrap;
              }
            }
            &:hover {
              background: #2e2e2e;
              .storeIcon {
                svg {
                  fill: $primary-color;
                }
              }
            }
            &.qrItem {
              width: auto;
              min-width: auto;
              max-width: auto;
              height: 138px;
              min-height: 138px;
              max-height: 138px;
              .storeIcon {
                display: flex;
                min-width: 98px;
                min-height: 98px;
                max-height: 98px;
                max-width: 98px;
                svg {
                  display: flex;
                  width: 100%;
                  height: auto;
                  border-radius: 8px;
                }
              }
            }
          }
        }
      }
    }
  }
}

//height 940px media query
@media (max-height: 980px) {
  .authBody {
    .authContent {
      .slideSection {
        .slideText {
          .title {
            font-size: 3.2rem;
          }
          .subTitle {
            font-size: 1.3rem;
          }
        }
        .customerQuotes {
          .quote {
            .quoteText {
              font-size: 16px;
            }
            .quoteAuthor {
              .quoteAuthorName {
                font-size: 14px;
              }
              .quoteAuthorDesignation {
                font-size: 12px;
              }
            }
          }
          .quoteDots {
            .quoteDot {
              width: 8px;
              height: 8px;
            }
          }
        }
      }
      .formSection {
        gap: 40px;
        justify-content: unset;
        .formTitle {
          .title {
            font-size: 1.8rem;
          }
          .subTitle {
            font-size: 16px;
          }
        }
        .form {
          .formItem {
            .title {
              font-size: 14px;
            }
            input {
              font-size: 16px;
              padding: 14px;
            }
            .forgotPasswordBtn {
              font-size: 14px;
            }
            .signBtn {
              font-size: 14px;
              min-height: 40px;
              padding: 10px;
            }
          }
        }
        .switchQuestion {
          .switchText {
            font-size: 16px;
          }
          .switchBtn {
            font-size: 18px;
          }
        }
        .downloadSection {
          margin-top: 0px;
          .titleSection {
            .title {
              font-size: 16px;
            }
            .subTitle {
              font-size: 14px;
            }
          }
          .storeList {
            .storeItem {
              .storeDetail {
                .title {
                  font-size: 10px;
                }
                .storeName {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-height: 768px) {
  .authBody {
    .authContent {
      .slideSection {
        .slideText {
          .title {
            font-size: 2.8rem;
          }
          .subTitle {
            font-size: 1.1rem;
          }
        }
        .customerQuotes {
          .quote {
            .quoteText {
              font-size: 14px;
            }
            .quoteAuthor {
              .quoteAuthorName {
                font-size: 12px;
              }
              .quoteAuthorDesignation {
                font-size: 10px;
              }
            }
          }
          .quoteDots {
            .quoteDot {
              width: 6px;
              height: 6px;
            }
          }
        }
      }
      .formSection {
        gap: 30px;
        .formTitle {
          .title {
            font-size: 1.6rem;
          }
          .subTitle {
            font-size: 14px;
          }
        }
        .form {
          .formItem {
            .title {
              font-size: 12px;
            }
            input {
              font-size: 14px;
              padding: 12px;
            }
            .forgotPasswordBtn {
              font-size: 12px;
            }
            .signBtn {
              font-size: 12px;
              min-height: 36px;
              padding: 8px;
            }
          }
        }
        .switchQuestion {
          .switchText {
            font-size: 14px;
          }
          .switchBtn {
            font-size: 16px;
          }
        }
        .downloadSection {
          .titleSection {
            .title {
              font-size: 14px;
            }
            .subTitle {
              font-size: 12px;
            }
          }
          .storeList {
            .storeItem {
              .storeDetail {
                .title {
                  font-size: 8px;
                }
                .storeName {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}

//1280px width
@media (max-width: 1280px) {
  .authBody {
    .authContent {
      .slideSection {
        .slideText {
          .title {
            font-size: 3.5rem;
          }
          .subTitle {
            font-size: 1.4rem;
          }
        }
        .customerQuotes {
          .quote {
            .quoteText {
              font-size: 17px;
            }
            .quoteAuthor {
              .quoteAuthorName {
                font-size: 15px;
              }
              .quoteAuthorDesignation {
                font-size: 13px;
              }
            }
          }
          .quoteDots {
            .quoteDot {
              width: 9px;
              height: 9px;
            }
          }
        }
      }
      .formSection {
        .formTitle {
          .title {
            font-size: 2rem;
          }
          .subTitle {
            font-size: 17px;
          }
        }
        .form {
          .formItem {
            .title {
              font-size: 15px;
            }
            input {
              font-size: 17px;
              padding: 15px;
            }
            .forgotPasswordBtn {
              font-size: 15px;
            }
            .signBtn {
              font-size: 15px;
              min-height: 42px;
              padding: 11px;
            }
          }
        }
        .switchQuestion {
          .switchText {
            font-size: 17px;
          }
          .switchBtn {
            font-size: 19px;
          }
        }
        .downloadSection {
          .titleSection {
            .title {
              font-size: 17px;
            }
            .subTitle {
              font-size: 15px;
            }
          }
          .storeList {
            .storeItem {
              .storeDetail {
                .title {
                  font-size: 11px;
                }
                .storeName {
                  font-size: 15px;
                }
              }
            }
          }
        }
      }
    }
  }
}

//980px width
@media (max-width: 980px) {
  .authBody {
    .authContent {
      .slideSection {
        width: 40dvw;
        .slideText {
          .title {
            font-size: 3.2rem;
          }
          .subTitle {
            font-size: 1.3rem;
          }
        }
        .customerQuotes {
          .quote {
            .quoteText {
              font-size: 16px;
            }
            .quoteAuthor {
              .quoteAuthorName {
                font-size: 14px;
              }
              .quoteAuthorDesignation {
                font-size: 12px;
              }
            }
          }
          .quoteDots {
            .quoteDot {
              width: 8px;
              height: 8px;
            }
          }
        }
      }
      .formSection {
        width: calc(100% - 40dvw);
        padding-left: 40px;
        padding-right: 40px;
        .formTitle {
          .title {
            font-size: 1.8rem;
          }
          .subTitle {
            font-size: 16px;
          }
        }
        .form {
          .formItem {
            .title {
              font-size: 14px;
            }
            input {
              font-size: 16px;
              padding: 14px;
            }
            .forgotPasswordBtn {
              font-size: 14px;
            }
            .signBtn {
              font-size: 14px;
              min-height: 40px;
              padding: 10px;
            }
          }
        }
        .switchQuestion {
          .switchText {
            font-size: 16px;
          }
          .switchBtn {
            font-size: 18px;
          }
        }
        .downloadSection {
          .titleSection {
            .title {
              font-size: 16px;
            }
            .subTitle {
              font-size: 14px;
            }
          }
          .storeList {
            .storeItem {
              .storeDetail {
                .title {
                  font-size: 10px;
                }
                .storeName {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }
}

//768px width
@media (max-width: 768px) {
  .authBody {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    .authContent {
      flex-direction: column;
      gap: 20px;
      overflow: auto;
      padding: 20px;
      .slideSection {
        display: none;
        width: 100%;
        .slideText {
          .title {
            font-size: 2.8rem;
          }
          .subTitle {
            font-size: 1.1rem;
          }
        }
        .customerQuotes {
          .quote {
            .quoteText {
              font-size: 14px;
            }
            .quoteAuthor {
              .quoteAuthorName {
                font-size: 12px;
              }
              .quoteAuthorDesignation {
                font-size: 10px;
              }
            }
          }
          .quoteDots {
            .quoteDot {
              width: 6px;
              height: 6px;
            }
          }
        }
      }
      .formSection {
        width: 100%;
        height: auto;
        overflow: unset;
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 0px;
        .formTitle {
          .mobileLogo {
            display: flex;
          }
          .title {
            font-size: 1.6rem;
          }
          .subTitle {
            font-size: 14px;
          }
        }
        .form {
          .formItem {
            .title {
              font-size: 12px;
            }
            input {
              font-size: 14px;
              padding: 12px;
            }
            .forgotPasswordBtn {
              font-size: 12px;
            }
            .signBtn {
              font-size: 12px;
              min-height: 36px;
              padding: 8px;
            }
          }
        }
        .switchQuestion {
          .switchText {
            font-size: 14px;
          }
          .switchBtn {
            font-size: 16px;
          }
        }
        .downloadSection {
          margin-top: 10px;
          .titleSection {
            .title {
              font-size: 14px;
            }
            .subTitle {
              font-size: 12px;
            }
          }
          .storeList {
            flex-direction: column-reverse;
            .columnItem {
              width: 100% !important;
              min-width: 100% !important;
              max-width: 100% !important;
            }
            .storeItem {
              width: 100% !important;
              min-width: 100% !important;
              max-width: 100% !important;
              .storeDetail {
                .title {
                  font-size: 8px;
                }
                .storeName {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}
