.clientSwitcher {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  background: #1d1d22;
  padding: 10px;
  border-radius: 12px;
  gap: 5px;
  margin-top: auto;
  transition: 0.3s;
  .brandLogo {
    width: 100%;
    height: 70px;
    min-height: 70px;
    max-height: 70px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 6px;
      object-fit: cover;
    }
  }
  .brandDesc {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    gap: 2px;
    .brandTitle {
      font-size: 14px;
      color: #fafafa;
    }
    .brandLocation {
      font-size: 12px;
      color: #8e8e93;
    }
  }
  &:hover {
    cursor: pointer;
    background: #2c2c32;
  }
}

@media screen and (max-width: 768px) {
  .clientSwitcher {
    .brandLogo {
      width: 100% !important;
      height: 70px !important;
      min-height: 70px !important;
      max-height: 70px !important;
    }
    .brandDesc {
      display: flex !important;
    }
  }
}
