@import "../../styles/variables.scss";
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
  padding: 24px;
  height: 54px;
  width: 100%;
  min-width: 100%;
  background: $dark-header-bg;
  .mobileMenu {
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;
    height: 54px;
    .hamburgerIcon {
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        filter: brightness(0.8);
      }
    }
    .mobileLogo {
      img {
        width: auto;
        height: 30px;
        path {
          fill: $primary-color;
        }
      }
    }
  }
  .message {
    color: #fcfcfc;
    font-size: 20px;
    font-weight: 600;
    margin-right: auto;
  }
  .search {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    input {
      background: #323232;
      width: 300px;
      height: 36px;
      color: $dark-text-color;
      font-size: 16px;
      border-radius: 6px;
      outline: none;
      border: none;
      cursor: text;
      box-sizing: border-box;
      padding: 6px 52px 6px 20px;
    }
    img {
      position: absolute;
      right: 20px;
      height: 22px;
      width: 22px;
      cursor: pointer;
    }
  }
  .userMenu {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    margin-left: auto;
    .profileMenu {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      .profilePicture {
        border-radius: 50%;
        height: 34px;
        width: 34px;
      }
      .profileInfo {
        display: flex;
        flex-direction: column;
        gap: 2px;
        .name {
          font-size: 16px;
          color: $dark-text-color;
        }
        .username {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 4px;
          font-size: 14px;
          color: #868b90;
          .platform {
            height: 14px;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .header {
    left: 0;
    .mobileMenu {
      display: flex;
    }
  }
}

@media (max-width: 430px) {
  .header {
    padding: 24px 12px;
    .mobileMenu {
      gap: 8px;
      .mobileLogo {
        width: 30px;
        height: 30px;
      }
    }
  }
}
