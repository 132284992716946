.statusCircle {
  background: #888888;
  border-radius: 50%;
  display: inline-block;
  height: 11px;
  margin-right: 6px;
  max-height: 11px;
  max-width: 11px;
  min-height: 11px;
  min-width: 11px;
  vertical-align: middle;
  width: 11px;
  &.colorActive {
    background: #4caf4f;
  }
  &.colorDraft {
    background: #fbb401;
  }
  &.colorPending {
    background: #00bdaf;
  }
}
