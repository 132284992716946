.masterLayout {
  display: flex;
  flex-direction: row;
  width: 100%;
  .content {
    display: flex;
    flex-direction: column;
    width: calc(100% - 230px);
    margin-left: auto;
    transition: 0.3s;
    .contentContainer {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 1260px;
      box-sizing: border-box;
      padding: 0px 40px;
      height: auto;
      margin: 0 auto;
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }
}

.loadingContainer {
  width: 100%;
  height: 100vh;
}

@media (max-width: 768px) {
  .masterLayout {
    .content {
      width: 100% !important;
      margin-left: 0;
      .contentContainer {
        padding: 0px 20px;
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
  }
}

@media (max-width: 430px) {
  .masterLayout {
    .content {
      .contentContainer {
        padding: 0px 10px;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }
}
