@import "../../styles/variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Russo+One&display=swap");
@keyframes beta {
  0% {
    box-shadow: 0 0 3px #f33333;
  }
  50% {
    box-shadow: 0 0 16px #f33333;
  }
  to {
    box-shadow: 0 0 3px #f33333;
  }
}

@keyframes new {
  0% {
    box-shadow: 0 0 3px $primary-color;
  }
  50% {
    box-shadow: 0 0 16px $primary-color;
  }
  to {
    box-shadow: 0 0 3px $primary-color;
  }
}

.sidebar {
  display: flex;
  flex-direction: column;
  width: 230px;
  min-width: 230px;
  height: 100vh;
  box-sizing: border-box;
  padding: 16px;
  gap: 30px;
  background: $dark-menu-bg;
  position: fixed;
  transition: 0.3s;
  .logoSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: auto;
    flex-wrap: nowrap;
    gap: 5px;
    .logo {
      width: auto;
      height: 30px;
      align-self: flex-start;
      path {
        fill: $primary-color;
      }
    }
    .hiddenLogo {
      width: auto;
      height: 30px;
      align-self: flex-start;
      display: none;
      path {
        fill: $primary-color;
      }
    }
    .toggleSidebarSize {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: fit-content;
      height: auto;
      background: transparent;
      border: none;
      outline: none;
      svg {
        width: 22px;
        height: 22px;
        color: $dark-text-color;
        cursor: pointer;
        transition: 0.2s;
        fill: #868b90;
      }
      &.active {
        svg {
          transform: rotate(-180deg);
        }
      }
      &:hover {
        svg {
          fill: #6e7275;
        }
      }
    }
  }

  .close {
    display: none;
    position: fixed;
    top: 21px;
    right: 16px;
    cursor: pointer;
    img {
      width: 20px;
      height: 20px;
      filter: brightness(0.5);
      transition: 0.3s;
    }
    &:hover {
      img {
        filter: brightness(1);
      }
    }
  }
  .menu {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    height: auto;
    .menuItem {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      box-sizing: border-box;
      padding: 12px;
      font-size: 16px;
      color: #868b90;
      background: transparent;
      border-radius: 8px;
      cursor: pointer;
      transition: 0.3s;
      .icon {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }
      svg {
        stroke-width: 0;
      }
      &.active {
        background: $primary-color;
        color: $dark-text-color;
        img {
          filter: brightness(2);
        }
        &:hover {
          background: $primary-color;
        }
      }
      &:hover {
        background: #262729;
        color: $dark-text-color;
        img {
          filter: brightness(2);
        }
      }
      .beta {
        background: #f33333;
        font-size: 10px;
        color: #fafafa;
        padding: 2px;
        border-radius: 3px;
        text-transform: uppercase;
        animation: beta 2s ease-in-out infinite;
      }
      .new {
        background: $primary-color;
        font-size: 10px;
        color: #fafafa;
        padding: 2px;
        border-radius: 3px;
        text-transform: uppercase;
        animation: new 2s ease-in-out infinite;
      }
    }
  }
  .promoteWidget {
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 100%;
    max-width: 228px;
    height: auto;
    cursor: pointer;
    left: 16px;
    bottom: 16px;
    background: #3a3a3a;
    box-sizing: border-box;
    padding: 16px;
    border-radius: 16px;
    transition: 0.3s;
    &:hover {
      background: #2e2e2e;
    }
    img {
      display: flex;
      align-self: flex-start;
      width: 100%;
      margin: 0 auto;
    }
    .slogan {
      font-family: "Russo One", sans-serif;
      bottom: 0px;
      font-size: 18px;
      width: 100%;
      text-align: center;
      i {
        color: $primary-color;
      }
    }
  }
}

@media (max-width: 768px) {
  .sidebar {
    transform: translateX(-300px);
    position: fixed;
    width: 230px !important;
    min-width: 230px !important;
    &.active {
      transform: translateX(0);
      z-index: 100;
      box-shadow: 0 0 20px #0a0a0a;
      .close {
        display: flex;
      }
    }
    .logoSection {
      .logoMark {
        display: none !important;
      }
      .hiddenLogo {
        display: unset !important;
      }
      .toggleSidebarSize {
        display: none;
      }
    }
    .menu {
      .menuItem {
        justify-content: unset !important;
        .sideBarItem {
          display: unset !important;
        }
      }
    }
  }
}
