@import "styles/variables.scss";

.dataGrid {
  height: calc(100dvh - 200px);
  a {
    color: $primary-color;
  }
  .dataIcon {
    height: 18px;
    min-height: 18px;
    max-height: 18px;
    margin-right: 6px;
    vertical-align: middle;
  }
  .circleDataIcon {
    height: 18px;
    min-height: 18px;
    max-height: 18px;
    margin-right: 6px;
    vertical-align: middle;
    border-radius: 50%;
  }
  .roundedDataIcon {
    height: 18px;
    min-height: 18px;
    max-height: 18px;
    margin-right: 6px;
    vertical-align: middle;
    border-radius: 2px;
  }

  .clickableDataCell {
    cursor: pointer;
    color: $primary-color;
    .flex {
      display: flex;
      flex-direction: row;
      align-items: center;
      &.right {
        flex-direction: row-reverse;
      }
    }
  }
}
