.customComponentCell {
  .dataIcon {
    height: 18px;
    min-height: 18px;
    max-height: 18px;
    margin-right: 6px;
    vertical-align: middle;
  }
  .circleDataIcon {
    height: 18px;
    min-height: 18px;
    max-height: 18px;
    margin-right: 6px;
    vertical-align: middle;
    border-radius: 50%;
  }
  .roundedDataIcon {
    height: 18px;
    min-height: 18px;
    max-height: 18px;
    margin-right: 6px;
    vertical-align: middle;
    border-radius: 2px;
  }
}
