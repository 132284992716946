@import "src/styles/variables.scss";
.dropdownMenu {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 10px;
  top: 54px;
  background: #1a1c1f;
  box-sizing: border-box;
  height: auto;
  padding: 12px;
  border-radius: 6px;
  width: 260px;
  right: 24px;
  z-index: 100;
  &:before {
    content: "";
    position: absolute;
    top: -7px;
    left: 78%;
    width: 0;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    border-bottom: 16px solid #1a1c1f;
  }
  .adminMenu {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .adminUserSearch {
      display: flex;
      flex-direction: row;
      width: 100%;
      input {
        width: 100%;
        outline: none;
        border: none;
        background: #292929;
        box-sizing: border-box;
        padding: 10px 12px;
        border-radius: 6px 0px 0px 6px;
        color: $dark-text-color;
        font-size: 16px;
      }
      button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 0px 6px 6px 0px;
        box-sizing: border-box;
        height: 38.33px;
        width: 38.33px;
        padding-right: 12px;
        border: none;
        background: #292929;
        cursor: pointer;
        img {
          height: 20px;
          transition: 0.3s;
          &:hover {
            filter: brightness(1.4);
          }
        }
      }
    }
    .adminUserSearchResults {
      display: flex;
      flex-direction: column;
      gap: 10px;
      background: #323232;
      color: #7d7d7d;
      border-radius: 12px;
      padding: 12px 12px;
      box-sizing: border-box;
      .adminUserResultItem {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
        cursor: pointer;
        transition: 0.3s;
        box-sizing: border-box;
        padding: 4px 6px;
        border-radius: 6px;
        &:hover {
          background: #3d3d3d;
        }
        .platform {
          height: 14px;
        }
        .picture {
          height: 14px;
          border-radius: 50%;
        }
        .username {
          font-size: 16px;
          color: #cecece;
        }
      }
    }
  }
  .dropdownMenuItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    cursor: pointer;
    padding: 10px 12px;
    box-sizing: border-box;
    color: #979797;
    font-weight: 500;
    background: #28292d;
    transition: 0.2s;
    border-radius: 10px;
    img {
      height: 16px;
      filter: brightness(0.6);
      transition: 0.2s;
    }
    &:hover {
      background: #313336;
      color: #fafafa;
      img {
        filter: brightness(1);
      }
    }
    .dropdownMenuItemText {
      font-size: 16px;
      color: $dark-text-color;
    }
    &.isMobile {
      display: none;
    }
  }
  .languageSelect {
    display: none;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 6px;
    cursor: pointer;
    padding: 10px 12px;
    box-sizing: border-box;
    transition: 0.2s;
    outline: none;
    background: #292929;
    border-radius: 6px;
    font-size: 16px;
    color: $dark-text-color;
    border: none;
  }
}

@media (max-width: 768px) {
  .dropdownMenu {
    .dropdownMenuItem {
      &.isMobile {
        display: flex;
      }
    }
  }
  .dropdownMenu {
    .languageSelect {
      display: flex;
    }
  }
}
