$primary-color: #0075ff;
$secondary-color: #1a1c1f;
$stroke-color: #313336;
$background-color: #16181b;
$white-color: #fafafa;
$title-color: #78818a;
$dark-bg: #16181b;
$dark-scrollbar-track: rgba(29, 29, 29, 0.5);
$dark-scrollbar-thumb: rgba(45, 45, 45, 0.5);
$dark-menu-bg: #131517;
$dark-header-bg: #131517;
$dark-text-color: #fafafa;
