.authBody {
  width: 100%;
  height: 100%;
  background: #121212;
  position: fixed;
  top: 0;
  left: 0;
  .login {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    max-width: 600px;
    margin: 0 auto;
    margin-top: 40px;
    background-color: #222;
    padding: 20px;
    border-radius: 10px;
    box-sizing: border-box;
    .loginTitle {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: auto;
      box-sizing: border-box;
      color: #0075ff;
      font-size: 2rem;
      font-weight: 700;
    }
    .subTitle {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;
      width: 100%;
      height: auto;
      .loginLink {
        color: #0075ff;
        font-weight: 500;
        cursor: pointer;
        transition: 0.3s;
        &:hover {
          opacity: 0.8;
        }
      }
    }
    .form {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: auto;
      box-sizing: border-box;
      gap: 10px;
      margin-top: 20px;
      .section {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: auto;
      }
      input {
        background: #2e2e2e;
        border: none;
        outline: none;
        cursor: pointer;
        transition: 0.3s;
        border-radius: 6px;
        padding: 10px;
        box-sizing: border-box;
        color: #fafafa;
        font-size: 16px;
        width: 100%;
        height: auto;
      }
      button {
        background: #0075ff;
        color: #fafafa;
        border: none;
        cursor: pointer;
        border-radius: 10px;
        padding: 10px 10px;
        box-sizing: border-box;
        font-weight: 600;
        font-size: 14px;
        margin-left: auto;
        align-self: flex-end;
      }
    }
  }
}
